import React from 'react';
import { motion } from 'framer-motion';
import { 
  ShieldCheckIcon, 
  CreditCardIcon, 
  SparklesIcon 
} from '@heroicons/react/24/outline';

// Trust Badges Data
const trustBadgesData = [
  {
    id: 1,
    icon: ShieldCheckIcon,
    title: "100% Satisfaction",
    description: "Guaranteed or your money back"
  },
  {
    id: 2,
    icon: CreditCardIcon,
    title: "Secure Payments",
    description: "SSL Encrypted Transactions"
  },
  {
    id: 3,
    icon: SparklesIcon,
    title: "Lifetime Access",
    description: "To course materials"
  }
];

const TrustBadge = ({ icon: Icon, title, description }) => (
  <div className="flex items-center space-x-4 bg-white/10 p-4 rounded-xl">
    <Icon className="w-10 h-10 text-violet-500" />
    <div>
      <h4 className="font-bold text-white">{title}</h4>
      <p className="text-white/70 text-sm">{description}</p>
    </div>
  </div>
);

const FinalCTA = () => {
  return (
    <div className="relative bg-gradient-to-br from-violet-600 to-indigo-700 text-white overflow-hidden">
      <div className="container mx-auto px-4 py-16 relative z-10">
        <div className="grid md:grid-cols-2 items-center gap-12">
          {/* Text Content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4">
              Your Creative Career Awaits!
            </h2>
            <p className="text-xl text-white/80 mb-8">
              Don't miss this chance to learn, grow, and shine.
            </p>

            {/* CTA Button */}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="
                bg-white 
                text-violet-600 
                font-bold 
                px-8 py-3 
                rounded-full 
                shadow-lg 
                hover:shadow-xl 
                transition-all 
                duration-300
              "
            >
              Enroll Today and Start Designing
            </motion.button>

            {/* Trust Badges */}
            <div className="mt-12 grid md:grid-cols-3 gap-4">
              {trustBadgesData.map((badge) => (
                <TrustBadge 
                  key={badge.id}
                  icon={badge.icon}
                  title={badge.title}
                  description={badge.description}
                />
              ))}
            </div>
          </motion.div>

          {/* Visual Mockup */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="hidden md:block relative"
          >
            <div className="
              bg-white/10 
              rounded-2xl 
              p-6 
              backdrop-blur-sm 
              border 
              border-white/20
            ">
              <div className="
                bg-gray-200 
                h-64 
                rounded-xl 
                flex 
                items-center 
                justify-center
                mb-4
              ">
                <SparklesIcon className="w-24 h-24 text-gray-400" />
              </div>
              <div className="grid grid-cols-3 gap-2">
                {[1,2,3].map((item) => (
                  <div 
                    key={item} 
                    className="bg-white/10 h-16 rounded-lg"
                  ></div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Background Decorative Elements */}
      <div className="
        absolute 
        top-0 
        left-0 
        w-full 
        h-full 
        bg-gradient-to-br 
        from-violet-600 
        to-indigo-700 
        opacity-20 
        pointer-events-none
      "></div>
    </div>
  );
};

export default FinalCTA;