import { title } from "framer-motion/client";
import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const footer = {
  explore: [
    {
      title: "About Us",
    },

    { title: "Our Courses", end_point: "courses" },
    { title: "Become an Affiliate", end_point: "team" },
    { title: "Need Help? (FAQs)", end_point: "team" },
    { title: "Our Team", end_point: "team" },
  ],
  legalLink: [
    { title: "Refund Policy", end_point: "refund_policies" },
    { title: "Privacy Policy", end_point: "terms" },
    { title: "Cookie Policy", end_point: "terms" },
    { title: "Compliance Information", end_point: "terms" },
  ],
};

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="bg-gray-900 text-white py-16">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Left Section: Logo and Copyright */}
          <div className="space-y-4">
            <div className="flex items-center">
              <img src="/logo.svg" className="w-14" alt="logo"/>
              <h3 className="text-xl font-bold">DegitalDo</h3>
            </div>
            <p className="text-gray-400 text-sm">Empowering Your Future</p>
            <p className="text-gray-500 text-sm">
              © 2024 Design Masters. All rights reserved.
            </p>

            {/* Social Media Icons */}
            <div className="flex space-x-4 pt-4">
              {[
                { Icon: FaFacebook, link: "#" },
                { Icon: FaTwitter, link: "#" },
                { Icon: FaInstagram, link: "#" },
                { Icon: FaLinkedin, link: "#" },
                { Icon: FaYoutube, link: "#" },
              ].map(({ Icon, link }, index) => (
                <a
                  key={index}
                  href={link}
                  className="
                    text-gray-400 
                    hover:text-white 
                    transition-colors 
                    duration-300
                  "
                >
                  <Icon className="w-6 h-6" />
                </a>
              ))}
            </div>
          </div>

          {/* Middle Section: Legal */}
          <div>
            <h4 className="font-bold mb-4 text-lg">Legal</h4>
            {footer?.legalLink.map((item, index) => (
              <Link
                key={index}
                to={item?.end_point}
                onClick={()=>window.scrollTo({ top: 0, behavior: "smooth" })}
                className="
                 block 
                  text-gray-400 
                  hover:text-white 
                  py-2 
                  transition-colors 
                  duration-300
                "
              >
                {item?.title}
              </Link>
            ))}
          </div>

          {/* Right Section: Explore */}
          <div>
            <h4 className="font-bold mb-4 text-lg">Explore</h4>
            {footer?.explore.map((item, index) => (
              <Link
                key={index}
                to={item?.end_point}
                onClick={()=>window.scrollTo({ top: 0, behavior: "smooth" })}
                className="
                  block 
                  text-gray-400 
                  hover:text-white 
                  py-2 
                  transition-colors 
                  duration-300
                "
              >
                {item?.title}
              </Link>
            ))}
          </div>

          {/* Newsletter Signup */}
          <div>
            <h4 className="font-bold mb-4 text-lg">Stay Updated</h4>
            <p className="text-gray-400 mb-4">
              Subscribe to our newsletter for the latest design trends and
              courses.
            </p>
            <div className="flex">
              <input
                type="email"
                placeholder="Enter your email"
                className="
                  w-full 
                  px-4 
                  py-2 
                  rounded-l-lg 
                  bg-gray-800 
                  text-white 
                  focus:outline-none 
                  focus:ring-2 
                  focus:ring-violet-600
                "
              />
              <button
                className="
                  bg-violet-600 
                  text-white 
                  px-4 
                  py-2 
                  rounded-r-lg 
                  hover:bg-violet-700 
                  transition-colors 
                  duration-300
                "
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>

        {/* Bottom Border */}
        <div className="border-t border-gray-800 mt-8 pt-4 text-center">
          <p className="text-gray-500 text-sm">
            Designed with ❤️ by DigitalDo
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
