import React from "react";

import Footer from "./Footer";
import Header from "./Header";
import { courseData,bundles } from "../../utils/mockdata";
import CourseCard from "./CourseCard";
import BundleCard from "./BundleCard";

// Simulated course data


// Helper to get level color

// Individual Course Card

// Course Bundle Card


// Main Component
const CourseSection = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  return (
    <>
    
    <div className="container mx-auto px-4 py-24 md:py-32">
      {/* Title */}
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-gray-800 mb-4">Our Courses</h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Explore individual courses or save more with our expertly curated bundles.
        </p>
      </div>
      {/* Individual Courses */}
      <div className="grid md:grid-cols-4 gap-6 mb-12">
        {courseData.map((course) => (
          <CourseCard key={course.id} course={course} />
        ))}
      </div>
      {/* Course Bundles */}
      <div className="text-center mb-6">
        <h3 className="text-3xl font-bold text-gray-800 mb-4">Exclusive Bundles</h3>
      </div>
      <div className="grid md:grid-cols-2 gap-6">
        {bundles.map((bundle) => (
          <BundleCard key={bundle.id} bundle={bundle} />
        ))}
      </div>
    </div>
   
    </>
  );
};

export default CourseSection;
