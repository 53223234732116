import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  StarIcon,
  UserIcon,
  DocumentCheckIcon,
  DocumentChartBarIcon,
  PlayCircleIcon,
  ShoppingCartIcon,
  ChevronDownIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";

import RecommendedCourses from "./RecommendedCourses";
import Header from "./Header";

// Sample Course Data Structure
const courseData = {
  id: "ui-ux-master-2024",
  title: "UI/UX Design Mastery 2024",
  instructor: {
    name: "Adhil",
    bio: "Senior UX Designer with 10+ years of experience",
  },
  details: {
    enrolledStudents: 1245,
    rating: 4.7,
    language: "Malayalam",
    price: 2999,
    originalPrice: 4999,
  },
  courseStructure: [
    
    {
      module: "Introduction to UI/UX",
      lectures: 5,
      duration: "2h 30m",
      subModules: [
        { title: "What is UI/UX Design", duration: "45m" },
        { title: "Design Thinking Process", duration: "1h 15m" },
        { title: "User Experience Fundamentals", duration: "30m" },
      ],
    },
    {
      module: "Design Principles",
      lectures: 7,
      duration: "3h 15m",
      subModules: [
        { title: "Color Theory", duration: "1h" },
        { title: "Typography Basics", duration: "45m" },
        { title: "Layout and Composition", duration: "1h 30m" },
        { title: "Visual Hierarchy", duration: "45m" },
      ],
    },
    {
      module: "Figma Masterclass",
      lectures: 8,
      duration: "4h 00m",
      subModules: [
        { title: "Figma Interface", duration: "30m" },
        { title: "Design Systems", duration: "1h 15m" },
        { title: "Prototyping", duration: "2h 15m" },
      ],
    },
    {
      module: "Advanced Interaction Design",
      lectures: 6,
      duration: "3h 45m",
      subModules: [
        { title: "Micro-interactions", duration: "1h" },
        { title: "Animation Principles", duration: "45m" },
        { title: "User Flow Design", duration: "1h" },
        { title: "Accessibility in Design", duration: "1h" },
      ],
    },
    
  ],
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 12,
      stiffness: 100,
    },
  },
};

const CourseDetails = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const [couponCode, setCouponCode] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeModule, setActiveModule] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isModuleDropdownOpen, setIsModuleDropdownOpen] = useState(false);

  // Check screen size
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Check on mount
    checkMobileView();

    // Add event listener for resize
    window.addEventListener("resize", checkMobileView);

    // Cleanup
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);

  const handleApplyCoupon = () => {
    if (couponCode.trim() === "SAVE20") {
      setAppliedCoupon({
        code: "SAVE20",
        discount: 20,
      });
    } else {
      setAppliedCoupon(null);
    }
  };

  const MobileModuleDropdown = () => (
    <div className="relative w-full">
      <motion.button
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsModuleDropdownOpen(!isModuleDropdownOpen)}
        className="w-full flex justify-between items-center bg-indigo-50 p-4 text-indigo-800 font-semibold"
      >
        <span>{courseData.courseStructure[activeModule].module}</span>
        <ChevronDownIcon
          className={`w-5 h-5 transition-transform ${
            isModuleDropdownOpen ? "rotate-180" : ""
          }`}
        />
      </motion.button>

      <AnimatePresence>
        {isModuleDropdownOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="overflow-hidden"
          >
            {courseData.courseStructure.map((module, index) => (
              <motion.div
                key={index}
                whileTap={{ scale: 0.98 }}
                onClick={() => {
                  setActiveModule(index);
                  setIsModuleDropdownOpen(false);
                }}
                className={`p-4 border-t ${
                  index === activeModule
                    ? "bg-indigo-100 text-indigo-800"
                    : "bg-white text-gray-700 hover:bg-gray-50"
                }`}
              >
                {module.module}
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  // Desktop Module Navigation
  const DesktopModuleNavigation = () => (
    <div className="flex border-b overflow-hidden overflow-x-scroll">
      {courseData.courseStructure.map((module, index) => (
        <motion.button
          key={index}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setActiveModule(index)}
          className={`flex-1 py-3 text-center transition ${
            activeModule === index
              ? "bg-indigo-100 text-indigo-800 font-semibold"
              : "text-gray-600 hover:bg-gray-50"
          }`}
        >
          Module {index + 1}
        </motion.button>
      ))}
    </div>
  );

  // Module Content Renderer
  const ModuleContent = () => {
    const activeModuleData = courseData.courseStructure[activeModule];
    return (
      <div className="p-6">
        <div className="flex justify-between mb-4">
          <h3 className="text-lg font-semibold text-indigo-700">
            {activeModuleData.module}
          </h3>
          <span className="text-gray-600">
            {activeModuleData.lectures} Lectures | {activeModuleData.duration}
          </span>
        </div>

        <div className="space-y-3">
          {activeModuleData.subModules.map((subModule, subIndex) => (
            <motion.div
              key={subIndex}
              whileHover={{ scale: 1.02 }}
              className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
            >
              <div className="flex items-center gap-3">
                <DocumentChartBarIcon className="w-6 text-indigo-600" />
                <span className="text-gray-700">{subModule.title}</span>
              </div>
              <span className="text-gray-500 text-sm">
                {subModule.duration}
              </span>
            </motion.div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="bg-gray-50 min-h-screen py-10 md:py-20"
    >
      
      <div className="container mx-auto px-4 py-12">
        <motion.div
          variants={containerVariants}
          className="grid md:grid-cols-3 gap-8"
        >
          {/* Left Column - Course Overview */}
          <motion.div
            variants={itemVariants}
            className="md:col-span-2 space-y-6"
          >
            {/* Course Title and Meta Info */}
            <motion.div
              variants={itemVariants}
              className="bg-white shadow-soft rounded-xl p-6"
            >
              <h1 className="text-3xl md:text-4xl font-bold text-indigo-800 mb-4">
                {courseData.title}
              </h1>

              <div className="flex flex-wrap items-center gap-4 text-gray-600">
                <div className="flex items-center gap-2">
                  <UserIcon className="w-5 h-5 text-indigo-500" />
                  <span>{courseData.details.enrolledStudents} Students</span>
                </div>
                <div className="flex items-center gap-2">
                  <StarIcon className="w-5 h-5 text-yellow-500" />
                  <span>{courseData.details.rating} Rating</span>
                </div>
                <div className="flex items-center gap-2">
                  <DocumentCheckIcon className="w-5 h-5 text-green-500" />
                  <span>{courseData.details.language}</span>
                </div>
              </div>
            </motion.div>

            {/* Video Preview */}
            <motion.div
              variants={itemVariants}
              className="bg-white shadow-soft rounded-xl overflow-hidden"
            >
              <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="relative h-64 md:h-80 bg-gray-100 flex items-center justify-center cursor-pointer"
                onClick={() => setIsVideoOpen(true)}
              >
                <PlayCircleIcon className="w-20 h-20 text-indigo-600 drop-shadow-lg" />
                <div className="absolute bottom-4 right-4 bg-indigo-600 text-white px-4 py-2 rounded-full">
                  Course Preview
                </div>
              </motion.div>
            </motion.div>

            {/* Instructor Details */}
            <motion.div
              variants={itemVariants}
              className="bg-white shadow-soft rounded-xl p-6 flex items-center gap-6"
            >
              <div className="bg-indigo-100 rounded-full p-4">
                <UserIcon className="w-16 h-16 text-indigo-600" />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-indigo-800">
                  {courseData.instructor.name}
                </h3>
                <p className="text-gray-600 mt-2">
                  {courseData.instructor.bio}
                </p>
              </div>
            </motion.div>
          </motion.div>

          {/* Right Column - Course Structure & Purchase */}
          <motion.div variants={itemVariants} className="space-y-6">
            {/* Pricing and Purchase */}
            <motion.div
              variants={itemVariants}
              className="bg-white shadow-soft rounded-xl p-6"
            >
              <div className="flex items-center justify-between mb-4">
                <div>
                  <span className="text-3xl font-bold text-indigo-800">
                    ₹{courseData.details.price}
                  </span>
                  <span className="ml-3 line-through text-gray-400">
                    ₹{courseData.details.originalPrice}
                  </span>
                </div>
                <div className="bg-green-100 text-green-800 px-3 py-1 rounded-full">
                  {Math.round(
                    (1 -
                      courseData.details.price /
                        courseData.details.originalPrice) *
                      100
                  )}
                  % OFF
                </div>
              </div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 transition flex items-center justify-center gap-2 mb-4"
              >
                <ShoppingCartIcon className="w-6 h-6" />
                Add to Cart
              </motion.button>

              {/* Coupon Code Section */}
              <div className="border-t pt-4">
                <div className="flex items-center border rounded-lg overflow-hidden">
                  <div className="pl-3 text-gray-400">
                    <TicketIcon className="w-5 h-5" />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Coupon Code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    className="flex-grow px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200"
                  />
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleApplyCoupon}
                    className="bg-indigo-100 text-indigo-700 px-4 py-2 hover:bg-indigo-200 transition"
                  >
                    Apply
                  </motion.button>
                </div>
                <AnimatePresence>
                  {appliedCoupon && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="mt-2 text-green-600 text-sm flex items-center"
                    >
                      ✓ Coupon {appliedCoupon.code} applied successfully!
                    </motion.div>
                  )}
                  {couponCode && !appliedCoupon && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="mt-2 text-red-600 text-sm"
                    >
                      Invalid coupon code
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>

            {/* Course Structure with Mobile-Friendly Tabs */}
            <motion.div
              variants={itemVariants}
              className="bg-white shadow-soft rounded-xl overflow-hidden"
            >
              <div className="bg-indigo-50 p-4 border-b border-gray-200">
                <h2 className="text-xl font-semibold text-indigo-800">
                  Course Structure
                </h2>
              </div>
              {/* Conditional Rendering for Mobile/Desktop */}
              {isMobile ? (
                <MobileModuleDropdown />
              ) : (
                <DesktopModuleNavigation />
              )}

              {/* Module Content */}
              <ModuleContent />
            </motion.div>
          </motion.div>
        </motion.div>
      </div>

      <RecommendedCourses />
    </motion.div>
  );
};
export default CourseDetails;
