import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  EnvelopeIcon, 
  LockClosedIcon, 
  UserIcon, 
  GoogleIcon ,
  XMarkIcon
} from '@heroicons/react/24/outline';

const AuthComponent = ({onClose,isButtonLogin}) => {
  const [isLogin, setIsLogin] = useState(isButtonLogin ? true : false);
 

  

  const cardRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (cardRef.current && !cardRef.current.contains(event.target)) {
  //       onClose && onClose();
  //     }
  //   };

  //   // Add event listener
  //   document.addEventListener('mousedown', handleClickOutside);
    
  //   // Cleanup listener
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [onClose]);

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        duration: 0.5,
        type: "spring",
        stiffness: 100 
      }
    },
    exit: { 
      opacity: 0, 
      scale: 0.9,
      transition: { duration: 0.3 }
    }
  };

  const handleGoogleSignIn = () => {
    // TODO: Implement Firebase Google Sign-In
    console.log('Google Sign-In');
  };

  const handleEmailSignIn = (e) => {
    e.preventDefault();
    // TODO: Implement Firebase Email Sign-In
    console.log('Email Sign-In');
  };

  const handleEmailSignUp = (e) => {
    e.preventDefault();
    // TODO: Implement Firebase Email Sign-Up
    console.log('Email Sign-Up');
  };
  return (
    <div className="fixed w-full z-50 min-h-screen flex items-center justify-center backdrop-blur-sm  -mt-12 md:mt-0 p-4">
      <motion.div 
        className="w-full max-w-md bg-white rounded-2xl shadow-2xl overflow-hidden"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {/* Close Button */}
        

        <div className=" relative p-8">
          <AnimatePresence mode="popLayout">
          <button 
          onClick={onClose}
          className="absolute top-4 right-4 z-10 text-gray-500 hover:text-gray-700"
        >
          <XMarkIcon className="h-6 w-6 " />
        </button>
            {isLogin ? (
              <motion.div 
                key="login"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
                transition={{ duration: 0.3 }}
              >
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  Login to Your Account
                </h2>
                
                <form onSubmit={handleEmailSignIn} className="space-y-4">
                  {/* Email Input */}
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <EnvelopeIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input 
                      type="email" 
                      placeholder="Email Address" 
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
                    />
                  </div>
                  
                  {/* Password Input */}
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <LockClosedIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input 
                      type="password" 
                      placeholder="Password" 
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
                    />
                  </div>
                  
                  {/* Submit Button */}
                  <button 
                    type="submit" 
                    className="w-full bg-violet-600 text-white py-2 rounded-lg hover:bg-violet-700 transition-colors"
                  >
                    Login
                  </button>
                  
                  {/* Google Sign-In */}
                  <div className="relative my-4">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-4 bg-white text-gray-500">or</span>
                    </div>
                  </div>
                  
                  <button 
                    type="button"
                    onClick={handleGoogleSignIn}
                    className="w-full flex justify-center items-center bg-white border border-gray-300 text-gray-700 py-2 rounded-lg hover:bg-gray-50 transition-colors space-x-2"
                  >
                    <svg 
                      className="w-5 h-5" 
                      viewBox="0 0 24 24" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" 
                        fill="#4285F4"
                      />
                      <path 
                        d="M12 23c2.97 0 5.46-1 7.28-2.69l-3.57-2.77c-.99.69-2.26 1.1-3.71 1.1-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C4.09 20.53 7.79 23 12 23z" 
                        fill="#34A853"
                      />
                      <path 
                        d="M5.84 14.11c-.22-.69-.35-1.43-.35-2.11s.13-1.42.35-2.11V7.05H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.95l2.66-2.84z" 
                        fill="#FBBC05"
                      />
                      <path 
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.46 2.09 14.97 1 12 1 7.79 1 4.09 3.47 2.18 7.05l3.66 2.84c.87-2.6 3.3-4.51 6.16-4.51z" 
                        fill="#EA4335"
                      />
                    </svg>
                    <span>Sign in with Google</span>
                  </button>
                  
                  {/* Switch to Signup */}
                  <div className="text-center mt-4">
                    <span className="text-gray-600">
                      Don't have an account?{' '}
                      <button 
                        type="button"
                        onClick={() => setIsLogin(false)}
                        className="text-violet-600 hover:text-violet-800 font-semibold"
                      >
                        Sign Up
                      </button>
                    </span>
                  </div>
                </form>
              </motion.div>
            ) : (
              <motion.div 
                key="signup"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.3 }}
              >
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  Create an Account
                </h2>
                
                <form onSubmit={handleEmailSignUp} className="space-y-4">
                  {/* Name Input */}
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <UserIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input 
                      type="text" 
                      placeholder="Full Name" 
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
                    />
                  </div>
                  
                  {/* Email Input */}
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <EnvelopeIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input 
                      type="email" 
                      placeholder="Email Address" 
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
                    />
                  </div>
                  
                  {/* Password Input */}
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <LockClosedIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input 
                      type="password" 
                      placeholder="Password" 
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
                    />
                  </div>
                  
                  {/* Submit Button */}
                  <button 
                    type="submit" 
                    className="w-full bg-violet-600 text-white py-2 rounded-lg hover:bg-violet-700 transition-colors"
                  >
                    Sign Up
                  </button>
                  
                  {/* Google Sign-Up */}
                  <div className="relative my-4">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-4 bg-white text-gray-500">or</span>
                    </div>
                  </div>
                  
                  <button 
                    type="button"
                    onClick={handleGoogleSignIn}
                    className="w-full flex justify-center items-center bg-white border border-gray-300 text-gray-700 py-2 rounded-lg hover:bg-gray-50 transition-colors space-x-2"
                  >
                    <svg 
                      className="w-5 h-5" 
                      viewBox="0 0 24 24" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" 
                        fill="#4285F4"
                      />
                      <path 
                        d="M12 23c2.97 0 5.46-1 7.28-2.69l-3.57-2.77c-.99.69-2.26 1.1-3.71 1.1-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C4.09 20.53 7.79 23 12 23z" 
                        fill="#34A853"
                      />
                      <path 
                        d="M5.84 14.11c-.22-.69-.35-1.43-.35-2.11s.13-1.42.35-2.11V7.05H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.95l2.66-2.84z" 
                        fill="#FBBC05"
                      />
                      <path 
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.46 2.09 14.97 1 12 1 7.79 1 4.09 3.47 2.18 7.05l3.66 2.84c.87-2.6 3.3-4.51 6.16-4.51z" 
                        fill="#EA4335"
                      />
                    </svg>
                    <span>Sign up with Google</span>
                  </button>
                  
                  {/* Switch to Login */}
                  <div className="text-center mt-4">
                    <span className="text-gray-600">
                      Already have an account?{' '}
                      <button 
                        type="button"
                        onClick={() => setIsLogin(true)}
                        className="text-violet-600 hover:text-violet-800 font-semibold"
                      >
                        Log In
                      </button>
                    </span>
                  </div>
                </form>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </div>
  );
};

export default AuthComponent;