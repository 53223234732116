import React, { useState } from 'react';
import { ChevronDownIcon, DocumentCheckIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import { Disclosure } from '@headlessui/react';
import { policyContent } from '../../utils/mockdata';

// Policy Content Objects


const PolicyPage = ({ 
  policyType = 'returnAndRefund', 
  customColor = 'violet' 
}) => {
  const policy = policyContent[policyType];

  return (
    <div className="min-h-screen bg-white py-28 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto space-y-8">
        <div className="text-center">
          <h1 className={`text-3xl font-bold text-${customColor}-600 mb-4`}>
            {policy.title}
          </h1>
          <p className="text-gray-500">{policy.subtitle}</p>
        </div>

        <div className="space-y-4">
          {policy.sections.map((section, index) => (
            <Disclosure key={index} as="div" className="border-b border-gray-200 pb-4">
              {({ open }) => (
                <>
                  <Disclosure.Button 
                    className={`
                      w-full flex justify-between items-center 
                      text-left text-lg font-medium 
                      text-${customColor}-700 hover:text-${customColor}-900 
                      transition-colors duration-300
                    `}
                  >
                    <span className="flex items-center">
                      <DocumentCheckIcon className={`w-6 h-6 mr-3 text-${customColor}-500`} />
                      {section.title}
                    </span>
                    <ChevronDownIcon
                      className={`
                        w-6 h-6 transform transition-transform duration-300
                        ${open ? 'rotate-180' : ''}
                      `}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel 
                    className="mt-2 text-gray-600 text-base leading-relaxed"
                  >
                    {section.content}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>

        <div className="text-center mt-8">
          <div className="flex justify-center items-center space-x-4 text-gray-500">
            <ShieldCheckIcon className="w-8 h-8 text-violet-500" />
            <p>Your privacy and rights are our top priority</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyPage;