import React from 'react';

import { courseData } from '../../utils/mockdata';
import CourseCard from './CourseCard';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

// Simulated course data (would typically come from a database)



const WhatYouWillLearn = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  const navigate=useNavigate()
  return (
    <div className="container mx-auto px-4 py-16">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-gray-800 mb-4">What You'll Learn</h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Master the tools and skills that top designers use.
        </p>
      </div>

      <div className="grid md:grid-cols-4 gap-6 mb-10">
        {courseData.map(course => (
          <CourseCard key={course.id} course={course} />
        ))}
      </div>

      {/* View All Courses Button */}
      <div className="flex justify-center">
        <button 
        onClick={()=>navigate("/courses")}
        
          className="
            flex items-center justify-center 
            px-8 py-3 
            bg-violet-600 text-white 
            rounded-full 
            font-semibold 
            hover:bg-violet-700 
            transition-all 
            duration-300 
            shadow-md 
            hover:shadow-xl 
            group
          "
        >
          View All Courses
          <ArrowRightIcon 
            className="
              w-5 h-5 
              ml-3 
              transition-transform 
              duration-300 
              group-hover:translate-x-1
            " 
          />
        </button>
      </div>
    </div>
  );
};

export default WhatYouWillLearn;