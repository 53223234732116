import React, { useState } from 'react';
import { UserCircleIcon, StarIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';

// Simulated testimonial data (would typically come from a database)
const testimonialData = [
  {
    id: 1,
    name: "John Doe",
    role: "Freelance Designer",
    quote: "This course transformed my career! I went from an amateur to a freelance designer in months.",
    rating: 5,
    avatar: null // Placeholder for future image
  },
  {
    id: 2,
    name: "Sarah Martinez",
    role: "UI/UX Designer",
    quote: "The live support and real projects helped me build confidence and land my first job.",
    rating: 5,
    avatar: null // Placeholder for future image
  },
  {
    id: 3,
    name: "Michael Wong",
    role: "Motion Graphics Artist",
    quote: "The community is so supportive; I never felt stuck.",
    rating: 5,
    avatar: null // Placeholder for future image
  }
];

const TestimonialCard = ({ testimonial }) => {
  return (
    <motion.div 
      className="bg-white p-6 rounded-xl shadow-lg relative overflow-hidden"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      {/* Decorative Violet Stroke */}
      <div className="absolute top-0 left-0 w-1 h-full bg-violet-600"></div>

      {/* Quote */}
      <p className="text-gray-700 italic mb-4 pl-4">
        "{testimonial.quote}"
      </p>

      {/* User Details */}
      <div className="flex items-center">
        {/* Avatar Placeholder */}
        <div className="mr-4">
          <UserCircleIcon className="w-12 h-12 text-gray-400" />
        </div>

        {/* User Info */}
        <div>
          <div className="flex items-center">
            <h4 className="font-semibold text-gray-800 mr-2">{testimonial.name}</h4>
            {/* Rating Stars */}
            <div className="flex">
              {[...Array(testimonial.rating)].map((_, i) => (
                <StarIcon key={i} className="w-5 h-5 text-yellow-400" />
              ))}
            </div>
          </div>
          <p className="text-gray-500 text-sm">{testimonial.role}</p>
        </div>
      </div>
    </motion.div>
  );
};

const GraduatesSays = () => {
  return (
    <div className="container mx-auto px-4 py-16 bg-gray-50">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-gray-800 mb-4">What Our Graduates Say</h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Hear from our successful alumni who transformed their careers with our courses.
        </p>
      </div>

      {/* Testimonials Grid */}
      <div className="grid md:grid-cols-3 gap-6">
        {testimonialData.map(testimonial => (
          <TestimonialCard 
            key={testimonial.id} 
            testimonial={testimonial} 
          />
        ))}
      </div>
    </div>
  );
};

export default GraduatesSays;