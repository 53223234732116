import React from 'react'
import Header from './subComponents/Header'
import MainComponent from './subComponents/MainComponent'
import WhyChooseUs from './subComponents/WhyChooseUs'
import WhatYouWillLearn from './subComponents/WhatYouWIllLearn'
import GraduatesSays from './subComponents/GraduatesSays'
import FAQ from './subComponents/Faq'
import FinalCTA from './subComponents/FinalCTA'
import Footer from './subComponents/Footer'

const Home = () => {
  return (
    <div className='overflow-hidden'>
      
      <MainComponent/>
      <WhyChooseUs/>
      <WhatYouWillLearn/>
      <GraduatesSays/>
      <FinalCTA/>
      
      
      
    </div>
  )
}

export default Home
