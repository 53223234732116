import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Home from './components/Home';
import CourseSection from './components/subComponents/CourseSection';
import ComingSoon from './components/subComponents/ComingSoon';
import PolicyPage from './components/subComponents/PolicyPage';
import CourseDetails from './components/subComponents/CourseDetails';
import TeamPage from './components/subComponents/TeamPage';
import Header from './components/subComponents/Header';
import Footer from './components/subComponents/Footer';

const MainLayout=()=>{
  return(
    <div>
      <Header/>
      <main>
        <Outlet/>
      </main>
      <Footer/>
    </div>
  )
}


const appRoute = createBrowserRouter([
  {
    path:"/abcd",
    element:<Home/>
  },
  {
    path:"/",
    element:<MainLayout/>,
    children:[
      {
        path:"",
        element:<Home/>
      },
      {
        path:"courses",
        element:<CourseSection/>,
        
      },
      {
        path:"policies",
        element:<PolicyPage/>
      },
      {
        path:"courseDetails",
        element:<CourseDetails/>
      },
      {
        path:"team",
        element:<TeamPage/>
    
      },
      {
        path:"refund_policies",
        element:<PolicyPage policyType = 'returnAndRefund' 
        customColor = 'violet' />
      },
{
        path:"terms",
        element:<PolicyPage policyType = 'termsAndConditions' 
        customColor = 'violet' />
      },
      
     
    ]
  },


])

function App() {
  return (
    <RouterProvider router={appRoute}/>
  );
}

export default App;
