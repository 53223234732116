import React from 'react';
import { motion } from 'framer-motion';
import { 
  BriefcaseIcon, 
  DevicePhoneMobileIcon 
} from '@heroicons/react/24/outline';
import { Linkedin, Instagram } from 'lucide-react';
import Header from './Header';

// Team data - can be easily modified or imported from another file
const teamMembers = [
  {
    id: 1,
    name: 'Adhil',
    designation: 'Mentor',
    // TODO: Replace with actual team member illustration or photo
    // illustration: import_illustration_here,
    image: 'https://i.ibb.co/vcjHffn/TEAM-IMG-adhil.jpg', 
    linkedin: 'https://linkedin.com/in/adhil',
    instagram: 'https://instagram.com/adhil'
  },
  {
    id: 2,
    name: 'Sreelesh',
    designation: 'Design & Development',
    // TODO: Replace with actual team member illustration or photo
    // illustration: import_illustration_here,
    image: 'https://i.ibb.co/68TndGZ/TEAM-IMG-sreelesh.jpg', 
    linkedin: 'https://linkedin.com/in/sreelesh',
    instagram: 'https://instagram.com/sreelesh'
  }
];

// Individual Team Member Card Component
const TeamMemberCard = ({ member }) => {
  return (
    <motion.div 
      className="bg-white group relative overflow-hidden rounded-2xl shadow-xl border border-violet-100 transform transition-all duration-300 hover:shadow-2xl hover:border-violet-200"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.6,
        type: "spring",
        stiffness: 100 
      }}
      whileHover={{ 
        scale: 1.05,
        rotate: 1
      }}
    >
      <div className="relative overflow-hidden rounded-t-2xl">
        <div className="absolute inset-0 bg-violet-500 opacity-10 group-hover:opacity-20 transition-opacity duration-300"></div>
        <img 
          src={member.image} 
          alt={member.name} 
          className="w-full aspect-square object-cover filter grayscale-[30%] group-hover:grayscale-0 transition-all duration-300"
        />
      </div>
      
      <div className="p-6 text-center bg-white relative z-10">
        <h3 className="text-xl font-bold text-gray-800 mb-1 group-hover:text-violet-700 transition-colors">
          {member.name}
        </h3>
        <p className="text-sm text-gray-600 mb-4">
          {member.designation}
        </p>
        
        <div className="flex justify-center space-x-4">
          <a 
            href={member.linkedin} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-violet-500 hover:text-violet-700 bg-violet-100 hover:bg-violet-200 p-2 rounded-full transition-all duration-300"
          >
            <Linkedin className="w-6 h-6" />
          </a>
          <a 
            href={member.instagram} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-violet-500 hover:text-violet-700 bg-violet-100 hover:bg-violet-200 p-2 rounded-full transition-all duration-300"
          >
            <Instagram className="w-6 h-6" />
          </a>
        </div>
      </div>
    </motion.div>
  );
};

// Team Page Component
const TeamPage = () => {
  return (
    <>
  
    <div className="container mx-auto px-4 py-28">
      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold text-gray-900 mb-4 text-gradient bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text text-transparent">
          Meet Our Team
        </h2>
        <p className="text-gray-600 max-w-2xl mx-auto text-lg">
          A passionate group of innovators dedicated to transforming learning experiences
        </p>
      </div>
      
      <div className="flex justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 max-w-2xl">
          {teamMembers.map((member) => (
            <TeamMemberCard key={member.id} member={member} />
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default TeamPage;