import React from 'react'

import { motion } from "framer-motion";
import { getLevelColor } from '../../utils/mockdata';

const BundleCard = ({ bundle }) => {
    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        className="bg-gray-50 rounded-xl shadow-md p-6"
      >
        <h3 className="text-2xl font-bold text-violet-700 mb-4">{bundle.title}</h3>
        <div className="mb-6">
          {bundle.courses.map((course) => (
            // <li key={course.id} className="text-gray-700 px-3 border-[0.1rem] py-2 my-2 flex justify-between">
            //    <span>{course.title} </span> <span>₹ {course?.price}</span>
            // </li>
            <>
             <div className="flex flex-wrap gap-y-2 justify-between items-center mb-4 border-[0.1rem] py-2 px-4 rounded-xl">
            <h3 className="text-xl font-bold text-gray-800 w-full md:w-[50%]">{course.title}</h3>
            <div className={`px-3 py-1 rounded-full text-xs  font-semibold text-left ${getLevelColor(course.level)}`}>
              {course.level}
            </div>
            <span className='w-[20%] md:w-[10%]'>₹ {course?.price}</span>
          </div>
            </>
          ))}
        </div>
        
        <p className="text-xl py-3 font-semibold text-gray-800 mb-4">Bundle Price: ₹{bundle.totalPrice}</p>
        <button className="w-full py-2 bg-violet-600 text-white rounded-full hover:bg-violet-700 transition-all">
          Enroll Bundle
        </button>
      </motion.div>
    );
  };

export default BundleCard
