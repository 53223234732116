import React from 'react';
import { StarIcon } from '@heroicons/react/24/outline';

// Sample Recommended Courses Data
const recommendedCoursesData = [
  {
    id: 'graphic-design-2024',
    title: 'Graphic Design Fundamentals',
    instructor: 'Arun Krishna',
    price: 1999,
    rating: 4.5,
    duration: '6 Weeks',
    level: 'Beginner',
    thumbnail: null // Placeholder for thumbnail
  },
  {
    id: 'web-development-2024',
    title: 'Full Stack Web Development',
    instructor: 'Rahul Nair',
    price: 3499,
    rating: 4.8,
    duration: '12 Weeks',
    level: 'Intermediate',
    thumbnail: null
  },
  {
    id: 'digital-marketing-2024',
    title: 'Digital Marketing Mastery',
    instructor: 'Priya Menon',
    price: 2499,
    rating: 4.6,
    duration: '8 Weeks',
    level: 'All Levels',
    thumbnail: null
  }
];

const RecommendedCourses = () => {
  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-violet-700 mb-8">
          Recommended Courses
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {recommendedCoursesData.map((course) => (
            <div 
              key={course.id} 
              className="bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            >
              {/* Thumbnail Placeholder */}
              <div className="bg-gray-200 h-48 w-full flex items-center justify-center">
                <span className="text-gray-500">Course Image</span>
              </div>
              
              <div className="p-5">
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {course.title}
                </h3>
                
                <div className="flex justify-between items-center mb-3">
                  <span className="text-gray-600">by {course.instructor}</span>
                  <div className="flex items-center">
                    <StarIcon className="w-5 h-5 text-yellow-500 mr-1" />
                    <span className="text-gray-700">{course.rating}</span>
                  </div>
                </div>
                
                <div className="flex justify-between items-center mb-4">
                  <div className="text-sm text-gray-600">
                    <span>{course.duration}</span>
                    <span className="mx-2">•</span>
                    <span>{course.level}</span>
                  </div>
                  <div className="text-violet-700 font-bold">
                    ₹{course.price}
                  </div>
                </div>
                
                <button className="w-full bg-violet-600 text-white py-2 rounded-lg hover:bg-violet-700 transition-colors">
                  View Course
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecommendedCourses;