import React from 'react'
import { ClockIcon, StarIcon, BookOpenIcon } from '@heroicons/react/24/outline';

const CourseCard = ({ course }) => {
    const getLevelColor = (level) => {
      switch(level) {
        case 'Beginner': return 'bg-green-100 text-green-800';
        case 'Intermediate': return 'bg-blue-100 text-blue-800';
        case 'Advanced': return 'bg-purple-100 text-purple-800';
        default: return 'bg-gray-100 text-gray-800';
      }
    };
  
    return (
      <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all hover:shadow-xl hover:-translate-y-2 duration-300">
        {/* Thumbnail Placeholder */}
        <div className="h-48 bg-gray-200 flex items-center justify-center">
          <BookOpenIcon className="w-16 h-16 text-gray-400" />
        </div>
  
        {/* Course Details */}
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold text-gray-800">{course.title}</h3>
            <span className={`px-3 py-1 rounded-full text-xs font-semibold ${getLevelColor(course.level)}`}>
              {course.level}
            </span>
          </div>
  
          <p className="text-gray-600 mb-4">{course.description}</p>
  
          {/* Course Stats */}
          <div className="grid grid-cols-3 gap-2 mb-4">
            <div className="flex items-center">
              <ClockIcon className="w-5 h-5 mr-2 text-gray-500" />
              <span className="text-sm">{course.hours}hrs</span>
            </div>
            <div className="flex items-center">
              <StarIcon className="w-5 h-5 mr-2 text-yellow-500" />
              <span className="text-sm">₹{course.price}</span>
            </div>
          </div>
  
          {/* Progress Bar */}
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-2">
            <div 
              className="bg-violet-600 h-2.5 rounded-full" 
              style={{width: `₹{course.progress}%`}}
            ></div>
          </div>
          <p className="text-xs text-gray-500 text-right">{course.progress}% Completed</p>
        </div>
      </div>
    );
  };

export default CourseCard
