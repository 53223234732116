import React from 'react';
import { 
  AcademicCapIcon, 
  ComputerDesktopIcon, 
  UserGroupIcon, 
  HeartIcon, 
  ChatBubbleLeftRightIcon, 
  DocumentCheckIcon 
} from '@heroicons/react/24/outline';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="relative group p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2">
    <div className="absolute -top-4 -right-4 w-16 h-16 bg-violet-100 rounded-full opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
    <div className="flex items-center mb-4">
      <div className="p-3 bg-violet-50 rounded-full mr-4">
        <Icon className="w-8 h-8 text-violet-600" />
      </div>
      <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
    </div>
    <p className="text-gray-600">{description}</p>
  </div>
);

const WhyChooseUs = () => {
  const features = [
    {
      icon: ComputerDesktopIcon,
      title: "Project-Based Learning",
      description: "Learn by doing with real-world projects that simulate professional experiences."
    },
    {
      icon: AcademicCapIcon,
      title: "Certification",
      description: "Get certified after completing 40% of the course to validate your skills."
    },
    {
      icon: ChatBubbleLeftRightIcon,
      title: "Live Support",
      description: "Attend weekly live sessions and get instant doubt clearance from experts."
    },
    {
      icon: DocumentCheckIcon,
      title: "Freelance Opportunities",
      description: "Launch your career with personalized guidance and job placement support."
    },
    {
      icon: UserGroupIcon,
      title: "Community Access",
      description: "Join our exclusive Discord design community to network and collaborate."
    },
    {
      icon: HeartIcon,
      title: "Portfolio Creation",
      description: "Showcase your work professionally to attract potential employers."
    }
  ];

  return (
    <div className="container mx-auto px-4 py-16 ">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-gray-800 mb-4">Why Choose Us?</h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Dive into a transformative learning experience designed to accelerate your design career.
        </p>
      </div>
      
      <div className="grid md:grid-cols-3 gap-6">
        {features.map((feature, index) => (
          <FeatureCard 
            key={index} 
            icon={feature.icon} 
            title={feature.title} 
            description={feature.description} 
          />
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;